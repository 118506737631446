import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import App from './App';

i18n.use(LanguageDetector).init({
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  resources: {
    en: {
      translation: {
        Oops: 'Oops',
        paragraph : 'Something went wrong, information about the crash',
        crash_message: 'will be sent to TWYNS'

      }
    },
    nl: {
      translation: {
        Oops: 'Oeps',
        paragraph : 'Er is iets misgegaan, informatie over de crash',
        crash_message : 'wordt naar TWYNS gestuurd'
      }
    }
  }
});

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root')
);
