import React from 'react';
import { useTranslation } from 'react-i18next';

function App() {
  const { t } = useTranslation();

  return (
    <div className="container">
      
      <p>
      <h1>
        {t('Oops')}
      </h1>
        {t('paragraph')}
        <p className="crash-message">
          {t('crash_message')}
        </p>
      </p>
    </div>
  );
}

export default App;
